<template>
  <el-main>
    <div class="search">
      <div class="left">
        <span>隔离解除</span>
        <span>{{ total_number }}人</span>
      </div>
      <div class="right">
        <el-input v-model="keywords" placeholder="请输入搜索..."></el-input>
        <el-button type="primary" @click="getList">搜索</el-button>
        <el-button @click="output">导出</el-button>
        <el-button @click="showImport = !0">导入</el-button>
      </div>
    </div>
    <el-table :data="list" row-key="id"
      ><el-table-column
        prop="shipper_info.equipment_no"
        label="编号"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="room_num"
        label="位置"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="name"
        label="姓名"
        align="center"
      ></el-table-column>
      <el-table-column prop="goods_name" label="性别" align="center">
        <template v-slot="{ row }">
          {{ row.sex ? "女" : "男" }}
        </template>
      </el-table-column>
      <el-table-column
        prop="current_temperature"
        label="当前体温"
        align="center"
      >
        <template v-slot="{ row }">
          <span v-if="row.current_temperature"
            >{{ row.current_temperature }}℃</span
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="maximum_temperature"
        label="最高体温"
        align="center"
      >
        <template v-slot="{ row }">
          <span
            v-if="row.maximum_temperature"
            :style="{
              color: row.maximum_temperature >= 37.3 ? '#FF0000' : '#1e92ff',
            }"
            >{{ row.maximum_temperature }}℃</span
          >
        </template>
      </el-table-column>
      <el-table-column prop="age" label="年龄" align="center"></el-table-column>
      <el-table-column
        prop="address"
        label="现住址"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="goods_name"
        label="开始观察期"
        align="center"
        width="150"
      >
        <template v-slot="{ row }">
          <span v-if="row.period_observation">{{
            getDateformat(row.period_observation)
          }}</span>
          <span v-else>--</span>
        </template>
      </el-table-column>
      <el-table-column prop="online_status" label="在/离线" align="center">
        <template v-slot="{ row }">
          <div class="online_status">
            <span v-show="row.online_status"></span>
            {{ row.online_status ? "在线" : "离线" }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="mobile"
        label="电话"
        align="center"
      ></el-table-column>
      <el-table-column prop="goods_name" label="状态" align="center">
        <template v-slot="{ row }">
          <span :style="{ color: row.status ? '#FF0000' : '' }">{{
            row.status ? "隔离中" : "解除隔离"
          }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="isolation_point" label="隔离点" align="center">
        <template #header>
          <span style="color: #1e92ff">隔离点</span>
        </template>
      </el-table-column>
      <el-table-column prop="shipper_info" label="负责人" align="center">
        <template #header>
          <span style="color: #1e92ff">负责人</span>
        </template>
        <template v-slot="{ row }">
          <span v-if="row.teacher_info.nickname">{{
            row.teacher_info.nickname
          }}</span>
          <span v-else>--</span>
        </template>
      </el-table-column>
      <el-table-column prop="classify_name" label="电话" align="center">
        <template #header>
          <span style="color: #1e92ff">电话</span>
        </template>
        <template v-slot="{ row }">
          <span v-if="row.teacher_info.mobile">{{
            row.teacher_info.mobile
          }}</span>
          <span v-else>--</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="goods_name"
        label="创建日期"
        align="center"
        width="150"
      >
        <template #header>
          <span style="color: #1e92ff">创建日期</span>
        </template>
        <template v-slot="{ row }">
          {{ getDateformat(row.last_login_time) }}
        </template>
      </el-table-column>
      <el-table-column
        prop="goods_name"
        label="操作"
        align="center"
        fixed="right"
      >
        <template v-slot="{ row }">
          <el-button size="small" @click="handleEdit(row)">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="page"
      :page-size="rows"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total_number"
    >
    </el-pagination>
    <el-dialog title="导入" :visible.sync="showImport" width="550px">
      <el-link type="primary" href="student.xlsx" download="隔离人员模板.xlsx"
        >下载导入模板</el-link
      >
      <el-input class="upload" v-model="filePath">
        <el-button slot="append">
          上传文件
          <input type="file" ref="inputer" @change="getFile" />
        </el-button>
      </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showImport = !1">取 消</el-button>
        <el-button type="primary" @click="comfirmImport">确 定</el-button>
      </span>
    </el-dialog>
  </el-main>
</template>

<script>
import { getDateformat } from "@/utils/getDate";
export default {
  data() {
    return {
      page: 1,
      rows: 10,
      keywords: "",
      list: [],
      total_number: 0,
      showImport: !1,
      formData: "",
      filePath: "",
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getFile() {
      let files = this.$refs.inputer.files[0];
      let formData = new FormData();
      formData.append("files", files);
      this.filePath = files.name;
      this.formData = formData;
    },
    comfirmImport() {
      if (!this.formData) return this.$message.warning("请先上传文件");
      this.$axios
        .uploadFile(this.$api.student.importStudent, this.formData)
        .then((res) => {
          if (res.code == 0) {
            this.showImport = !1;
            this.$message.success("导入成功");
            this.getList();
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    getList() {
      let data = {
        page: this.page,
        rows: this.rows,
        status: 0,
      };
      if (this.keywords) data.keywords = this.keywords;
      this.$axios.post(this.$api.student.preList, data).then((res) => {
        if (res.code == 0) {
          let list = res.result.list;
          list.map((item) => {
            item.teacher_info = item.teacher_info || {};
            item.shipper_info = item.shipper_info || {};
          });
          this.list = list;
          this.total_number = res.result.total_number;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //导出数据
    output() {
      this.$axios
        .post(this.$api.student.output, {
          page: this.page,
          rows: this.rows,
        })
        .then((res) => {
          if (res.code == 0) {
            let a = document.createElement("a");
            a.href = res.result.link;
            a.id = "download";
            document.body.appendChild(a);
            a.click();
            let aDom = document.getElementById("download");
            document.body.removeChild(aDom);
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    handleEdit(row) {
      this.$router.push({
        path: "/home/AddLsolationPersonnel",
        query: {
          info: JSON.stringify(row),
        },
      });
    },
    handleSizeChange(val) {
      this.rows = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getList();
    },
    getDateformat(val) {
      return getDateformat(val);
    },
  },
};
</script>
<style lang="less" scoped>
.el-main {
  color: #333;
  background: #fff;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  .search {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    .left {
      font-weight: bold;
      font-size: 18px;
      span:nth-child(2) {
        margin-left: 4px;
        color: #1E92FF;
      }
    }
    .right {
      .el-input {
        width: 270px;
        margin-right: 20px;
        /deep/ .el-input__inner {
          border-radius: 19px;
          margin-right: 20px;
        }
      }

      .el-button {
        border-radius: 19px;
      }
      .el-button--default {
        border-color: #409eff;
      }
    }
  }
  .el-table {
    border-top: 1px solid #ebeef5;
    border-left: 1px solid #ebeef5;
    border-right: 1px solid #ebeef5;
    margin-bottom: 10px;
  }
  .online_status {
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      width: 6px;
      height: 6px;
      background: #1e92ff;
      border-radius: 50%;
      margin-right: 4px;
    }
  }
  /deep/ .el-dialog__body {
    text-align: left;
  }
  .upload {
    margin-top: 20px;
    .el-button {
      position: relative;
      input {
        opacity: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
      }
    }
  }
}
</style>
